import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default function global() {
  const router = useRouter();
  const { t } = useI18n();

  function operationConfirm(route = '', id = '') {
    return Swal.fire({
      text: t('common.operationconfirm'),
      icon: 'success',
      buttonsStyling: false,
      confirmButtonText: t('common.ok'),
      customClass: {
        confirmButton: 'btn fw-bold btn-light-primary',
      },
    }).then(() => {
      if (route !== '') {
        router.push({ name: route, params: { id } });
      }
    });
  }

  function removeAlert() {
    return Swal.fire({
      text: t('common.removemessage'),
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: t('common.confirm'),
      cancelButtonText: t('common.cancel'),
      customClass: {
        confirmButton: 'btn fw-bold btn-light-primary',
        cancelButton: 'btn fw-bold btn-light-secondary',
      },
    });
  }

  function warningAlert(title) {
    return Swal.fire({
      text: title,
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: t('common.confirm'),
      cancelButtonText: t('common.cancel'),
      customClass: {
        confirmButton: 'btn fw-bold btn-light-primary',
        cancelButton: 'btn fw-bold btn-light-secondary',
      },
    });
  }

  function dangerAlert(text) {
    return Swal.fire({
      text: text,
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: t('common.ok'),
      customClass: {
        confirmButton: 'btn fw-bold btn-light-primary',
      },
    });
  }

  return {
    operationConfirm,
    removeAlert,
    dangerAlert,
    warningAlert,
  };
}
