import { createApp } from 'vue';
import i18n from '@/core/plugins/i18n';
import Parse from 'parse';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'bootstrap';
import { createPinia } from 'pinia';
import initInlineSvg from '@/core/plugins/inline-svg';
import App from './App.vue';
import router from './router';
Parse.initialize(process.env.VUE_APP_APPLICATION_ID!, process.env.VUE_APP_JS_KEY);
Parse.serverURL = 'https://parseapi.back4app.com/';

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(VueLoading, {
  color: '#009EF7',
  isFullPage: true,
});
app.use(i18n);

initInlineSvg(app);

app.mount('#app');
