<template>
  <div v-if="!isUploaded" class="cer-dropzone" :class="{'cer-dropzone-dragged': isDragged}"
       @dragenter.prevent="toggleEvent"
       @dragleave.prevent="toggleEvent"
       @dragover.prevent
       @drop.prevent="drop"
  >
    <h3 class="m-0">Drag & Drop</h3>
    <span class="fs-5 text-black-50">Seleziona un file o trascinalo qui</span>
    <label class="btn btn-light-primary" for="cer_dropzone_input">
      {{ $t("common.selectimage") }}
    </label>
    <input
      type="file"
      id="cer_dropzone_input"
      name="cer_dropzone_input"
      @change="select"
    />
  </div>
  <div v-else class="cer-dropzone-image">
    <img :src="fileName" alt="image">
    <button class="btn btn-secondary btn-sm btn-icon btn-circle" @click="deleteFile"><i class="bi bi-x-lg"></i></button>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import Parse from 'parse';

export default {
  emits: ['uploadFile', 'removeFile'],
  props: {
    initFile: Object,
  },
  setup(props, context) {
    const isDragged = ref(false);
    const file = ref(null);
    const fileName = ref('');

    const isUploaded = computed(() => fileName.value !== '');

    function fileReader() {
      const reader = new FileReader();
      reader.onload = function (img) {
        fileName.value = img?.target?.result ?? '';
      };
      reader.readAsDataURL(file.value);
      context.emit('uploadFile', new Parse.File(file.value.name, file.value));
    }

    function drop(e) {
      this.toggleEvent();
      file.value = e?.dataTransfer?.files[0] ?? null;
      fileReader();
    }

    function select(e) {
      file.value = e?.target?.files[0] ?? null;
      fileReader();
    }

    function toggleEvent() {
      this.isDragged = !this.isDragged;
    }

    function deleteFile() {
      file.value = null;
      fileName.value = '';
      context.emit('removeFile');
    }

    watch(() => props.initFile, (value) => {
      if (value !== null) {
        fileName.value = value.url;
      } else {
        deleteFile();
      }
    });

    return {
      toggleEvent,
      isDragged,
      drop,
      fileName,
      isUploaded,
      select,
      deleteFile,
    };
  },
};
</script>

<style scoped lang="scss">
  .cer-dropzone {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    border: 2px dashed #009EF7;
    border-radius: 5px;
    padding: 15px;

    input {
      display: none;
    }
  }
  .cer-dropzone-dragged {
    background-color: rgba(0, 158, 247, 0.1);
  }
  .cer-dropzone-image {
    position: relative;
    width: 150px;
    display: flex;

    img {
      width: 150px;
      height: auto;
      border-radius: 5px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(15px, -15px);
    }
  }
</style>
