import { defineStore } from 'pinia';
import ParseUser from 'parse';

interface User {
  username: string;
  email: string;
}

export const piniaAuth = defineStore('piniaAuth', {
  state: () => ({
    isLogged: false,
    isSuperUser: false,
    user: {} as User,
    institute: {} as any,
  }),
  getters: {
    getSuperUserStatus: (state) => state.isSuperUser,
    getLoginStatus: (state) => state.isLogged,
    getUsername: (state) => state.user.username,
    getEmail: (state) => state.user.email,
    getInitialUsername: (state) => state.user?.username?.charAt(0)?.toUpperCase(),
  },
  actions: {
    setAuth(isLogged, user = {}, isSuperUser = undefined as undefined | boolean) {
      this.isLogged = isLogged;
      if(isSuperUser !== undefined)
      this.isSuperUser = isSuperUser
      localStorage.setItem('isLogged', isLogged)
      if (Object.keys(user).length > 0) {
        // @ts-ignore
        this.user.username = (user as ParseUser).get('username');
        // @ts-ignore
        this.user.email = (user as ParseUser).get('email');
      } else {
        this.user = {} as User;
      }
    },
    setSuperUser(isSuperUser) {
      this.isSuperUser = isSuperUser;
    },
    setInstitute(institute) {
      this.institute = institute;
    },
  },
});

export const piniaBodyModule = defineStore('piniaBodyModule', {
  actions: {
    addBodyClassName(className) {
      document.body.classList.add(className);
    },
    removeBodyClassName(className) {
      document.body.classList.remove(className);
    },
    addBodyAttribute(payload) {
      const { qulifiedName, value } = payload;
      document.body.setAttribute(qulifiedName, value);
    },
    removeBodyAttribute(payload) {
      const { qulifiedName } = payload;
      document.body.removeAttribute(qulifiedName);
    },
  },
});

export const piniaRouter = defineStore('piniaRouter', {
  state: () => ({
    route: '',
    refresh: false,
  }),
  getters: {
    getRoute: (state) => state.route.toString(),
    getRefresh: (state) => state.refresh,
  },
});

export const piniaQuestion = defineStore('piniaQuestion', {
  state: () => ({
    refreshAnswers: false,
    refreshAnswersFromTip: '',
    refreshAnswersFromResource: '',
    objectIdAnswer: '',
    objectIdTip: '',
    objectIdResource: '',
  }),
  getters: {
    getRefresh: (state) => state.refreshAnswers,
    getRefreshAnswersFromTip: (state) => state.refreshAnswersFromTip,
    getRefreshAnswersFromResource: (state) => state.refreshAnswersFromResource,
    getObjectIdAnswer: (state) => state.objectIdAnswer,
    getObjectIdTip: (state) => state.objectIdTip,
    getObjectIdResource: (state) => state.objectIdResource,
  },
});

export const piniaAdventure = defineStore('piniaAdventure', {
  state: () => ({
    selectedTab: 0,
    refreshPhases: false,
    objectIdPhase: '',
    objectIdRoom: '',
    refreshPhasesFromRoom: '',
    objectIdAvatar: '',
    refreshAvatars: false,
  }),
  getters: {
    getSelectedTab: (state) => state.selectedTab,
    getRefreshPhases: (state) => state.refreshPhases,
    getObjectIdPhase: (state) => state.objectIdPhase,
    getObjectIdRoom: (state) => state.objectIdRoom,
    getRefreshPhasesFromRoom: (state) => state.refreshPhasesFromRoom,
    getObjectIdAvatar: (state) => state.objectIdAvatar,
    getRefreshAvatars: (state) => state.refreshAvatars,
  },
});

export const piniaSession = defineStore('piniaSession', {
  state: () => ({
    selectedTab: 0,
    refreshPhasesFromRoom: '',
    refreshPhasesFromPhase: false,
    objectIdPhase: '',
    objectIdRoom: '',
    refreshSession: false,
    objectIdTeam: '',
    typeTeam: '',
    SelezioneLiberaAvatar: undefined as undefined | boolean,
    refreshParticipants: false,
    objectIdParticipant: '',
    refreshOperators: false,
    objectIdOperator: '',
    refreshSessionsList: false,
    objectIdSession: '',
    refreshPhases: false,
    objectIdAdventure: '',
    isFinishedInterval: null as any,

  }),
  getters: {
    getSelezioneLiberaAvatar: (state) => state.SelezioneLiberaAvatar,
    getObjectIdAdventure: (state) => state.objectIdAdventure,
    getSelectedTab: (state) => state.selectedTab,
    getObjectIdPhase: (state) => state.objectIdPhase,
    getObjectIdRoom: (state) => state.objectIdRoom,
    getRefreshPhasesFromRoom: (state) => state.refreshPhasesFromRoom,
    getRefreshPhasesFromPhase: (state) => state.refreshPhasesFromPhase,
    getRefreshSession: (state) => state.refreshSession,
    getObjectIdTeam: (state) => state.objectIdTeam,
    getTypeTeam: (state) => state.typeTeam,
    getRefreshParticipants: (state) => state.refreshParticipants,
    getObjectIdParticipant: (state) => state.objectIdParticipant,
    getRefreshOperators: (state) => state.refreshOperators,
    getObjectIdOperator: (state) => state.objectIdOperator,
    getRefreshSessionsList: (state) => state.refreshSessionsList,
    getObjectIdSession: (state) => state.objectIdSession,
    getRefreshPhases: (state) => state.refreshPhases,
  },
});
