import { Modal } from 'bootstrap';

const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();
};

const showModal = (modalId: string): void => {
  const myModal = new Modal(document.getElementById(modalId.toString()));
  myModal.show();
};

const getModalInstance = (modalId: string): Modal => document.getElementById(modalId.toString());

export { hideModal, showModal, getModalInstance };
