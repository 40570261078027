import { createRouter, createWebHashHistory } from 'vue-router';
import TemplateLayout from '@/template/TemplateLayout.vue';
import ViewSessions from '@/views/ViewSessions.vue';
import ViewSessionEdit from '@/views/ViewSessionEdit.vue';
import ViewLogin from '@/views/ViewLogin.vue';
import ViewQuestions from '@/views/ViewQuestions.vue';
import ViewQuestionEdit from '@/views/ViewQuestionEdit.vue';
import ViewAdventures from '@/views/ViewAdventures.vue';
import ViewAdventureEdit from '@/views/ViewAdventureEdit.vue';
import ViewOperators from '@/views/ViewOperators.vue';
import ViewOperatorsEdit from '@/views/ViewOperatorsEdit.vue';
import ViewDevices from '@/views/ViewDevices.vue';
import ViewDeviceEdit from '@/views/ViewDeviceEdit.vue';
import ViewClasses from '@/views/ViewClasses.vue';
import ViewClassEdit from '@/views/ViewClassEdit.vue';
import ViewMemberEdit from '@/views/ViewMemberEdit.vue';
import ViewGroupEdit from '@/views/ViewGroupEdit.vue';
import ViewInstituteEdit from '@/views/ViewInstituteEdit.vue';
import ViewInstitutes from '@/views/ViewInstitute.vue';
import ViewAddSession from '@/views/ViewAddSession.vue'
import ViewDocumentation from '@/views/ViewDocumentation.vue'
import { piniaAuth, piniaRouter } from '@/pinia';
import back4App from '@/core/api';
const {get} = back4App()
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: '/sessions',
      component: TemplateLayout,
      children: [
        { path: '/sessions', name: 'Sessions', component: ViewSessions },
        { path: '/session-edit/:objectId?', name: 'SessionEdit', component: ViewSessionEdit },
        { path: '/questions', name: 'Questions', component: ViewQuestions },
        { path: '/question-edit/:objectId?', name: 'QuestionEdit', component: ViewQuestionEdit },
        { path: '/adventures', name: 'Adventures', component: ViewAdventures },
        { path: '/adventure-edit/:objectId?', name: 'AdventureEdit', component: ViewAdventureEdit },
        { path: '/operators', name: 'Operators', component: ViewOperators },
        { path: '/operators-edit/:objectId?', name: 'OperatorEdit', component: ViewOperatorsEdit },
        { path: '/devices', name: 'Devices', component: ViewDevices },
        { path: '/class-edit/:objectId?', name: 'ClassEdit', component: ViewClassEdit },
        { path: '/classes', name: 'Classes', component: ViewClasses },
        { path: '/institutes', name: 'Institutes', component: ViewInstitutes },
        { path: '/device-edit/:objectId?', name: 'DeviceEdit', component: ViewDeviceEdit },
        { path: '/member-edit/:objectId?', name: 'MemberEdit', component: ViewMemberEdit },
        { path: '/group-edit/:objectId?', name: 'GroupEdit', component: ViewGroupEdit },
        { path: '/institute-edit/:objectId?', name: 'InstituteEdit', component: ViewInstituteEdit },
        { path: '/add-session/:objectId?', name: 'AddSession', component: ViewAddSession },
        { path: '/documentation', name: 'Documentation', component: ViewDocumentation },
      ],
    },
    {
      path: '/login',
      component: ViewLogin,
      name: 'Login',
    },
  ],
});

router.beforeEach(async (to) => {
  const pinia = piniaRouter();
  const auth = piniaAuth();
  const isSuperUser = await get('isSuperUser')
  auth.setSuperUser(isSuperUser)
  if (to?.name) {
    pinia.route = to?.name?.toString()?.toLowerCase();
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
