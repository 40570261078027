import Parse from 'parse';
import { useLoading } from 'vue-loading-overlay';

export default function back4App() {
  const $loading = useLoading();

  function get(name, data = {}, loading = true) {
    let loader;
    if (loading) {
      loader = $loading.show();
    }
    return Parse.Cloud.run(name, data)
      .then((res) => res.data)
      .catch(() => {
        //console.clear();
      })
      .finally(() => {
        if (loading) {
          loader.hide();
        }
      });
  }

  function post(name, data, loading = true) {
    let loader;
    if (loading) {
      loader = $loading.show();
    }
    return Parse.Cloud.run(name, data)
      .then((res) => res.data)
      .catch(() => {
        //console.clear();
      })
      .finally(() => {
        if (loading) {
          loader.hide();
        }
      });
  }


  function put(name, data, loading = true) {
    let loader;
    if (loading) {
      loader = $loading.show();
    }
    return Parse.Cloud.run(name, data)
      .then((res) => res.data)
      .catch(() => {
        //console.clear();
      })
      .finally(() => {
        if (loading) {
          loader.hide();
        }
      });
  }



  function remove(name, data, loading = true) {
    let loader;
    if (loading) {
      loader = $loading.show();
    }
    return Parse.Cloud.run(name, data)
      .then((res) => res.data)
      .catch(() => {
        //console.clear();
      })
      .finally(() => {
        if (loading) {
          loader.hide();
        }
      });
  }

  return {
    get,
    post,
    put,
    remove,
  };
}
