<template>
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex flex-column flex-md-row align-items-center
      justify-content-between container-fluid"
    >
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1">2022&copy;</span>
        <a href="https://www.cascolearning.it" target="_blank" class="text-gray-800 text-hover-primary">Casco Learning</a>
      </div>
    </div>
  </div>
</template>
