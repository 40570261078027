import { piniaBodyModule } from '@/pinia';

export default function html() {
  const pinia = piniaBodyModule();

  function initLayout(): void {
    pinia.addBodyAttribute({ qulifiedName: 'id', value: 'kt_body' });
    pinia.addBodyClassName('page-loading-enabled');
    pinia.addBodyClassName('page-loading');
  }

  function initHeader(): void {
    pinia.addBodyClassName('header-fixed');
    pinia.addBodyClassName('header-tablet-and-mobile-fixed');
  }

  function initToolbar(): void {
    pinia.addBodyClassName('toolbar-enabled');
    pinia.addBodyClassName('toolbar-fixed');
    pinia.addBodyClassName('toolbar-tablet-and-mobile-fixed');
  }

  function initAside(): void {
    pinia.addBodyClassName('aside-enabled');
    pinia.addBodyClassName('aside-fixed');
  }

  function initFooter(): void {
    pinia.addBodyClassName('footer-fixed');
  }

  function init() {
    initLayout();
    initHeader();
    initToolbar();
    initAside();
    initFooter();
  }

  return { init };
}
