import { createI18n } from 'vue-i18n/index';

const messages = {
  it: {
    selectInstitute: 'Seleziona istituto',
    sessions: 'Sessioni',
    questions: 'Domande',
    adventuresettings: 'Gestione avventure',
    adventures: 'Avventure',
    sessionsettings: 'Gestione sessione',
    operators: 'Operatori',
    institutes: 'Istituto',
    operatorsettings: 'Gestione operatori',
    institutesettings: 'Gestione Istituto',
    documentation: 'Documentazione',
    devices: 'Dispositivi',
    classes: 'Classi',
    editClass: 'Modifica Classe',
    devicessettings: 'Gestione dispositivi',
    login: {
      title: "Accedi all'area privata",
      success: "Hai effettuato l'accesso",
    },
    question: {
      title: 'Modifica domanda',
      subtitle: 'Componi domande e risposte',
      subtitle2: 'Personalizza la domanda',
      totalrows: 'Domande totali: {n}',
    },
    class: {
      title: 'Modifica classe',
      subtitle: 'Aggiungi o modifica classe',
      subtitle2: 'Aggiungi membri',
      subtitle3: 'Modifica membro',
      totalrows: 'Classi totali: {n}',
    },
    adventure: {
      title: 'Modifica avventura',
      subtitle: 'Costruisci e personalizza l’avventura',
      subtitle2: "Personalizza l'avventura",
      totalrows: 'Avventure totali: {n}',
    },
    session: {
      title: 'Modifica sessione',
      subtitle: 'Modifica e avvia le sessioni di gioco',
      subtitle2: 'Personalizza la sessione di gioco',
      totalrows: 'Sessioni totali: {n}',
    },
    operator: {
      title: 'Modifica operatore',
      subtitle: 'Modifica o aggiungi operatore',
      subtitle2: 'Modifica o aggiungi operatore',
      totalrows: 'Operatori totali: {n}',
    },
    institute: {
      title: 'Modifica istituto',
      subtitle: 'Modifica istituto',
      subtitle2: 'Aggiungi istituto',
      altTitle: 'Aggiungi istituto',
      totalrows: 'istituti totali: {n}',
    },
    device: {
      title: 'Modifica dispositivo',
      subtitle: 'Modifica o aggiungi dispositivo',
      subtitle2: 'Modifica o aggiungi dispositivo',
      totalrows: 'Dispositivi totali: {n}',
    },
    common: {
      downloadReport: 'Download report',
      downloadReports: 'Scarica i report',
      multipleCodeRoom: 'Stanza a codici multipli',
      code: 'Codice',
      activateGameRoom: 'Stanza di attivazione del gioco',
      modifyresource: 'Modifica risorsa',
      modifytip : 'Modifica suggerimento',
      modifyparticipant: 'Modifica partecipante',
      modifyanswer: 'Modifica risposta',
      modifyoperator: 'Modifica operatore',
      modifyphase: 'Modifica fase',
      modifyroom: 'Modifica stanza',
      modifyavatar: 'Modifica avatar',
      textFields: 'Attributi descrittivi',
      roomDescription: 'Descrizione stanza',
      rewardText: "Messaggio di restituzione del codice d'avanzamento",
      unlockText: "Messaggio di invito all'inserimento del codice di sblocco",
      advancementText: "Messaggio d'invito a proseguire alla successiva sfida di gruppo",
      caratteristica: 'Caratteristica',
      addCaratteristica: 'Aggiungi caratteristica',
      handleAvatar: 'Gestisci avatar',
      address: 'Indirizzo',
      context: 'Contesto',
      city: 'Città',
      CAP: 'CAP',
      RagioneSociale: 'Ragione Sociale',
      gamePhase: 'Fase gioco',
      invalidOrder: 'Ordinamento non univoco',
      invalidGamePhase: 'Fase gioco non univoca',
      sendAllEmails: 'Invia tutte le Email',
      invalidCode: 'Codice non univoco',
      active: 'Attivo',
      ok: 'Ok',
      esc: 'Esci',
      notroomsinserted: 'Nessuna stanza inserita',
      retry: 'Riprova',
      email: 'Email',
      class: 'Classe',
      password: 'Password',
      signin: 'Accedi',
      add: 'Aggiungi',
      confirm: 'Conferma',
      operationconfirm: 'Operazione avvenuta con successo',
      question: 'Domanda',
      name: 'Nome',
      surname: 'Cognome',
      memo: 'Memo',
      type: 'Tipologia',
      selecttype: 'Seleziona tipologia',
      selectGroup: 'Seleziona Classe',
      cancel: 'Annulla',
      removemessage: "L'eliminazione è un'operazione irreversibile, si vuole proseguire?",
      user: 'Utente',
      openanswer: 'Riposta aperta',
      closeanswer: 'Risposta chiusa',
      answers: 'Risposte',
      focuson: 'Focus su',
      addanswer: 'Aggiungi risposta',
      answer: 'Risposta',
      focustheme: 'Tema focus',
      focustext: 'Testo focus',
      tips: 'Suggerimenti',
      nottipsinserted: 'Nessun suggerimento inserito',
      resources: 'Risorse',
      notresourcesinserted: 'Nessuna risorsa inserita',
      addtip: 'Aggiungi suggerimento',
      text: 'Testo',
      serial: 'Seriale',
      CodCaratteristica: 'Codice Caratteristica',
      DescrCaratteristica: 'Descrizione Caratteristica',
      addresource: 'Aggiungi risorsa',
      reference: 'Riferimento',
      referenceplaceholder: 'Inserisci il riferimento',
      link: 'Link web',
      bibliographicindication: 'Indicazione bibliografica',
      order: 'Posizione ordinale',
      orderplaceholder: "Inserisci la posizione ordinale",
      registry: 'Anagrafica',
      configurephases: 'Configura fasi',
      configureavatar: 'Configura avatar',
      shortdescription: 'Descrizione breve',
      adventure: 'Avventura',
      goodAnswer: 'Risposta positiva',
      goodAnswers: 'Riscontri positivi',
      badAnswer: 'Risposta negativa',
      addadventure: 'Aggiungi avventura',
      phases: 'Fasi',
      addphase: 'Aggiungi fase',
      phase: 'Fase',
      description: 'Descrizione',
      rooms: 'Stanze',
      addroom: 'Aggiungi stanza',
      noroomsinserted: 'Nessuna stanza inserita',
      room: 'Stanza',
      roomType: 'Tipologia stanza',
      unlockcode: 'Codice sblocco',
      unlockcodeplaceholder: 'Inserisci il codice sblocco',
      rewardcode: 'Codice avanzamento',
      rewardcodeplaceholder: 'Inserisci il codice di avanzamento',
      questions: 'Domande',
      start: 'Avvia',
      createdAt: 'Creata il {date}',
      addsession: 'Aggiungi sessione',
      session: 'Sessione',
      selectadventure: 'Seleziona avventura',
      enableddevices: 'Device disponibili',
      devices: 'Device',
      device: 'Device',
      team: 'Team',
      notphasesinserted: 'Nessuna fase inserita',
      notenableddevices: 'Nessun device disponibile',
      editcodes: 'Modifica codici',
      createteam: 'Crea il team',
      selectidentity: 'Seleziona identità',
      identityteam: 'Identità partecipanti',
      identityteam1: 'Nome e cognome',
      identityteam2: 'Nickname',
      identityteam3: 'Avatar',
      participants: 'Partecipanti',
      addparticipant: 'Aggiungi partecipante',
      addparticipants: 'Aggiungi partecipanti',
      notparticipantsinserted: 'Nessun partecipante inserito',
      selectmember: 'Seleziona membro',
      member: 'Membro',
      members: 'Membri',
      selectdevice: 'Seleziona device',
      operators: 'Operatori',
      addoperator: 'Aggiungi operatore',
      notoperatorsinserted: 'Nessun operatore inserito',
      operator: 'Operatore',
      selectoperator: 'Seleziona operatore',
      role: 'Ruolo',
      selectrole: 'Seleziona ruolo',
      facilitator: 'Facilitatore',
      animator: 'Animatore',
      educator: 'Educatore',
      errormultiplesessions: 'Può essere attiva una sola sessione',
      errornopartecipants: 'Il numero di partecipanti deve essere almeno 3',
      sessiondetail: 'Dettaglio sessione',
      close: 'Chiudi',
      stop: 'Concludi',
      inprogress: 'In corso',
      startedAt: 'Avviata il {date} alle {time}',
      stoppedAt: 'Conclusa il {date} alle {time}',
      stopped: 'Conclusa',
      startphasenmessage: "L'avvio di una fase è un'operazione irreversibile, si vuole proseguire?",
      stopphasenmessage: "La conclusione di una fase è un'operazione irreversibile, si vuole proseguire?",
      nickname: 'Nickname',
      stopsession: 'Concludi sessione',
      stopsessionnmessage: "La conclusione di una sessione è un'operazione irreversibile, si vuole proseguire?",
      nominalduration: 'Durata nominale (minuti)',
      nominaldurationplaceholder: 'Inserisci la durata nominale',
      sessionnominalduration: 'Durata nominale della sessione (minuti)',
      phasenominalduration: 'Durata nominale della fase (minuti)',
      editsessionnominalduration: 'Modifica durata nominale della sessione',
      nominaldurationtime: 'Durata nominale: {time} minuti',
      durationtime: 'Durata effettiva: {time} minuti',
      notanswersinserted: 'Nessuna risposta inserita',
      startsessionnmessage: "L'avvio di una sessione è un'operazione irreversibile, si vuole proseguire?",
      avatars: 'Avatar',
      addavatar: 'Aggiungi avatar',
      notavatarsinserted: 'Nessun avatar inserito',
      avatar: 'Avatar',
      image: 'Immagine',
      selectimage: 'Seleziona immagine',
      multipleCode0: 'Codice in caso di 0 risposte positive',
      multipleCode1: 'Codice in caso di 1 risposte positive',
      multipleCode2: 'Codice in caso di 2 risposte positive',
      multipleCode3: 'Codice in caso di 3 risposte positive',
      multipleCode4: 'Codice in caso di 4 risposte positive',
      otherAnswer: 'Possibile risposta aperta',
      generateReport: 'Genera report',
      sendEmails: 'Invia email',
    },
    wizard: {
      back: 'Indietro',
      next: 'Avanti',
      backToSessions: 'Torna alle sessioni'
    },
    form: {
      'text-required': 'Il valore è obbligatorio',
      'type-required': 'Il valore è obbligatorio',
      'order-required': 'Il valore è obbligatorio',
      'order-minValue': 'Il valore minimo consentito è {n}',
      'unlockcode-required': 'Il valore è obbligatorio',
      'unlockcode-minValue': 'Il valore minimo consentito è {n}',
      'unlockcode-maxValue': 'Il valore massimo consentito è {n}',
      'rewardcode-required': 'Il valore è obbligatorio',
      'rewardcode-minValue': 'Il valore minimo consentito è {n}',
      'rewardcode-maxValue': 'Il valore massimo consentito è {n}',
    },
  },
};

const i18n = createI18n({
  locale: 'it',
  messages,
});

export default i18n;
